import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class ChunkLoadErrorHandler implements ErrorHandler {
  
  // Reload automatically when chunkLoadError occurs after build updation 
  handleError(error: any): void {
   const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
  }
}