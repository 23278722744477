import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { TokenDetails } from '../models/user.interface';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  
  constructor(public _auth: AuthService, 
    private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const user: TokenDetails = JSON.parse(localStorage.getItem('CustomerPortalUser'));
    if(user){
      // Dont send token details in refresh token api
      if(request.url.includes('framework/generate-token')){
        request = request.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          }
        });
      } else {
        // Add token details if a user is present
        request = request.clone({
          setHeaders: {
            'Authorization': `Bearer ${user.token}`,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          }
        });
      }
    }
    return next.handle(request)
  }
  
}
