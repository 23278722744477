// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  staging: false,
  development: false,
  baseUrl: 'https://groupcert.easy-cert.cloud/v1',
  farmework_url: 'https://my.easy-cert.cloud/',
  totpToken: 'jLCRq5rm24tMPDqw',
  versionNumberFrontend: '1.3.2',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
