import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgIf } from '@angular/common';
import { SnackBarType } from 'src/app/shared/services/snackBar/snack-bar.service';

export interface SnackBarInterface{
  type: SnackBarType;
  message: string;
}

@Component({
    selector: 'app-snack-bar',
    templateUrl: './snack-bar.component.html',
    styleUrls: ['./snack-bar.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, NgIf, MatIconModule, TranslateModule]
})
export class SnackBarComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarInterface) {}

  get getIcon(): { type: SnackBarType, icon: string } {
    switch (this.data.type) {
      case 'success':
        return {type: this.data.type, icon: 'check'};
      case 'error':
        return {type: this.data.type, icon: 'faults'};
      case 'warn':
        return {type: this.data.type, icon: 'warning_outline'};
      case 'info':
        return {type: this.data.type, icon: 'info'};
    }
  }

  txtColor(type: SnackBarType){
    switch (type) {
      case 'success':
        return "text-white";
      case 'error':
        return 'text-danger';
      case 'warn':
        return 'text-warning';
      case 'info':
        return 'text-info';
    }
  }

}
