<div class="snack-container {{getIcon.type}}-toast">
    <div class="snack-container-message ">
      <div class="d-flex align-items-center" [ngClass]="txtColor(data.type)">
        <mat-icon *ngIf="data.type == 'success'" class="icon-size text-primary">
          check_circle</mat-icon>
        <mat-icon *ngIf="data.type == 'error'" class="icon-size text-danger">
          warning</mat-icon>
        <span *ngIf="data.type == 'success'" class="ml-3 text-primary">
          {{data.message | translate}}</span>
        <span *ngIf="data.type == 'error'" class="ml-3 text-danger">
            {{data.message | translate}}</span>
      </div>
    </div>
</div>