import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../../../core/snack-bar/snack-bar.component';
import { Router } from '@angular/router';
import { TokenDetails } from '../models/user.interface';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(
    public _authService: AuthService,
    public _snackBar: MatSnackBar,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        // let error = '';
        if (!window.navigator.onLine) {
          this.customSnackBar(
            'Network Error! Check your Internet Connection',
            'error'
          );
        }
        if (err.status === 401 && !this.isRefreshing) {
          this.handle401Error();
        }
        if ([403].includes(err.status)) {
          if (err?.error?.message?.detail === 'Invalid otp') {
            //if timestamp mismatches
            this.invalidTimeSnackBar();
          } else {
            //if user role is updated
            this.customSnackBar(
              'User role was updated, Redirecting to home page',
              'error'
            );
          }
        }
        if (err.status === 409) {
          this.customSnackBar(err.error.message['detail'], 'error');
        }
        if (err.status >= 500) {
          this.customSnackBar('SERVER_ERROR', 'error');
        }
        throw err.error;
      })
    );
  }

  private handle401Error() {
    this.isRefreshing = true;
    const user: TokenDetails = JSON.parse(
      localStorage.getItem('CustomerPortalUser')
    );
    this._authService.getRefreshToken({ token: user.token }).subscribe({
      next: (res: any) => {
        this.isRefreshing = false;
        localStorage.setItem('CustomerPortalUser', JSON.stringify(res.data));
        window.location.reload();
      },
      error: (err) => this.router.navigateByUrl('/login'),
    });
  }

  // Method to toast success/error messages (custom)
  customSnackBar(message: string, type: string, duration: number | null = 3000) {
    this._snackBar.openFromComponent(SnackBarComponent, {
      data: {
        message: message,
        type: type,
      },
      // If duration is not provided, default is 3000. Otherwise set it to null to prevent auto-closing
      //auto-close prevention is configured only for timestamp issue
      duration: duration
    });
  }

  invalidTimeSnackBar() {
    //let message = 'ERROR_TIMESTAMP';
    let message =
      'Error: Please verify your system time and date settings to ensure accurate functionality, and then refresh the application.';
    this.customSnackBar(message, 'error', null);
  }
}
