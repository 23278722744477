import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { BehaviorSubject, throttleTime } from 'rxjs';
import { ResponseType } from 'src/app/shared/models/response.interface';
import { ErrorMessage, TokenDetails } from '../models/user.interface';
import totp from 'totp-generator';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  baseUrl = environment.baseUrl;

  error_data = new BehaviorSubject<ErrorMessage>(null);

  constructor(private http: HttpClient) {}

  loginWithSSO() {
    let url = this.baseUrl + '/framework/login/';
    return this.http.post<ResponseType<{ url: string }>>(
      url,
      null,
      this.Options()
    );
  }

  logout() {
    let url = this.baseUrl + '/framework/logout/';
    return this.http.get<ResponseType<string>>(url);
  }

  getUserToken(data: { code: string; state: string }) {
    let url = this.baseUrl + `/framework/auth-complete/?code=${data.code}&state=${data.state}`;
    return this.http.get<ResponseType<TokenDetails>>(url, this.Options());
  }

  getRefreshToken(data) {
    let url = this.baseUrl + '/framework/generate-token/';
    return this.http.post(url, data, this.Options2WithTotp()).pipe(
      throttleTime(3000)
    );
  }

  Options2WithTotp() {
    const token = environment.totpToken;
    const httpOptions = {
      headers: new HttpHeaders({
        otp: totp(token)
      })
    };
    return httpOptions;
  }

  // httpOptions
  Options() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return httpOptions;
  }
}
