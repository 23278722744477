import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegisterService } from './shared/services/iconregister/mat-icon-register.service';
import { RouterOutlet } from '@angular/router';

export interface StateCode{
    state: string;
    code: string
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet]
})
export class AppComponent {

    constructor(public translate: TranslateService,
        private iconregister:MatIconRegisterService) {
        translate.setDefaultLang('en');
        iconregister.RegisterIcon()
    }

}
