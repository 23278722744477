import { BrowserModule } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import { Observable } from 'rxjs';
import { Router, provideRouter } from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import { CustomMatPaginatorIntl } from '../app/shared/services/pagination-transalte';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ChunkLoadErrorHandler } from './auth/services/interceptors/chunkLoadErrorHandler.interceptor';
import totp from 'totp-generator';
import { routes } from '../app/app-routing';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpHeaders,
  HttpParams,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ErrorInterceptor } from '../app/auth/services/interceptors/error.interceptors';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideEnvironmentNgxMask } from 'ngx-mask';

import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TokenInterceptor } from './auth/services/interceptors/token.interceptors';
import * as Sentry from '@sentry/angular-ivy';

class CustomLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    const params = { code: lang };
    const url = environment.baseUrl;
    return this.http.get(
      url + '/translations/public/json/download/',
      this.Options2WithTotp(params)
    );
  }

  getOTP() {
    const token = environment.totpToken;
    return totp(token);
  }

  Options2WithTotp(param) {
    const httpOptions = {
      headers: new HttpHeaders({
        otp: this.getOTP(),
      }),
      params: new HttpParams({ fromObject: param }),
    };
    return httpOptions;
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const appConfig: ApplicationConfig = {
  providers: [
    // provideEnvironmentNgxMask(),
    // provideAnimations(),
    provideRouter(routes),
    provideEnvironmentNgxMask(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ChunkLoadErrorHandler,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB',
    },
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        // Will show dialog for user feedback
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule,
      MatSnackBarModule,
      MatDialogModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: CustomLoader,
          // useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      })
    ),
  ],
};
