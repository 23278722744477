import { Injectable } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class MatIconRegisterService {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) { }
path='assets/images/icons'
RegisterIcon(){
  this.matIconRegistry.addSvgIcon("note",this.setpath(this.path+'/note.svg'));
  this.matIconRegistry.addSvgIcon( "clip",this.setpath(this.path+"/clip.svg"));
  this.matIconRegistry.addSvgIcon( "down",this.setpath(this.path+"/down-icon.svg"));
  this.matIconRegistry.addSvgIcon( "xml",this.setpath(this.path+"/xml_icon.svg"));
  this.matIconRegistry.addSvgIcon( "checked",this.setpath(this.path+"/checked.svg"));
  this.matIconRegistry.addSvgIcon( "toggle",this.setpath(this.path+"/round-button.svg"));
  this.matIconRegistry.addSvgIcon( "quiestion",this.setpath(this.path+"/question.svg"));
  this.matIconRegistry.addSvgIcon( "co2-light",this.setpath(this.path+"/co2-light.svg"));
  this.matIconRegistry.addSvgIcon( "co2-orange",this.setpath(this.path+"/co2-orange.svg"));
  this.matIconRegistry.addSvgIcon( "co2-green",this.setpath(this.path+"/co2-green.svg"));
  this.matIconRegistry.addSvgIcon( "input",this.setpath(this.path+"/input.svg"));
  this.matIconRegistry.addSvgIcon( "output",this.setpath(this.path+"/output.svg"));
  this.matIconRegistry.addSvgIcon( "completed",this.setpath(this.path+"/completed.svg"));
  this.matIconRegistry.addSvgIcon( "farm-house",this.setpath(this.path+"/farm-house.svg"));
  this.matIconRegistry.addSvgIcon( "pending-calculation",this.setpath(this.path+"/pending-calculation.svg"));
  this.matIconRegistry.addSvgIcon( "tag",this.setpath(this.path+"/tag.svg"));
  this.matIconRegistry.addSvgIcon( "climate_balance",this.setpath(this.path+"/climate_balance.svg"));
  this.matIconRegistry.addSvgIcon( "input_arrow",this.setpath(this.path+"/input_arrow.svg"));
  this.matIconRegistry.addSvgIcon( "input_arrow_lg",this.setpath(this.path+"/input_arrow_lg.svg"));
  this.matIconRegistry.addSvgIcon( "download",this.setpath(this.path+"/download.svg"));
}
setpath(url){
  return this.domSanitizer.bypassSecurityTrustResourceUrl(url)
}

}
