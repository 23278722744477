import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
// import { MatPaginatorIntl } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { map, tap, takeUntil} from 'rxjs/operators';
import { Subject } from 'rxjs';
​
@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl
  implements OnDestroy {
  unsubscribe: Subject<void> = new Subject<void>();
  OF_LABEL = 'of';

  constructor(private translate: TranslateService) {
    super();
​
    this.translate.onLangChange
        .pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(() => {
            this.getAndInitTranslations();
        }
    );
​
    this.getAndInitTranslations();
  }
​
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
​
  getAndInitTranslations() {
    this.translate
      .get([
        'MENU.ITEMS_PER_PAGE',
        'MENU.NEXT_PAGE',
        'MENU.PREVIOUS_PAGE',
        'MENU.OF_LABEL',
      ]).pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(translation => {
        this.itemsPerPageLabel =
          translation['MENU.ITEMS_PER_PAGE'];
        this.nextPageLabel = translation['MENU.NEXT_PAGE'];
        this.previousPageLabel =
          translation['MENU.PREVIOUS_PAGE'];
        this.OF_LABEL = translation['MENU.OF_LABEL'];
        this.changes.next();
      });
  }
​
  getRangeLabel = (
    page: number,
    pageSize: number,
    length: number,
  ) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.OF_LABEL} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${
      this.OF_LABEL
    } ${length}`;
  };
}
